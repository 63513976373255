<template>
	<div>
		<p style="padding:20px; min-height:400px">乐刷程序和刷机流程：<a href="https://share.weiyun.com/UNR5rdbn">https://share.weiyun.com/UNR5rdbn</a></p>
	</div>
</template>

<script>
	import Timeline from "view-design/src/components/timeline/timeline";
	import TimelineItem from "view-design/src/components/timeline/timeline-item";
	export default {
		components: {TimelineItem, Timeline},
		data: function () {
			return {
			}
		},
	}
</script>

<style scoped>
	.time{
		font-size: 14px;
		font-weight: bold;
	}
	.content{
		padding-left: 5px;
	}
</style>
